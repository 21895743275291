import React, { useEffect, useState } from "react";
import axios from "axios";
import "./BotList.css";
import { useLocation, useNavigate } from "react-router-dom";
import { LuPlus } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";

const BotList = ({ user }) => {
  const [botList, setBotList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  console.log(user.user.email);

  const fetchBotList = () => {
    if (user) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/file/getDetails`, {
          userId: user.user._id,
        })
        .then((response) => {
          if (response.data) {
            const sortedBotList = response.data.sort((a, b) => {
              if (!a.updatedAt) return 1;
              if (!b.updatedAt) return -1;
              return new Date(b.updatedAt) - new Date(a.updatedAt);
            });
            setBotList(sortedBotList);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchBotList();
    const interval = setInterval(fetchBotList, 15000);

    return () => clearInterval(interval);
  }, [user]);

  const handleAdminDashboard = (e) => {
    e.preventDefault();
    const data = { state: user };
    navigate("/admin", data);
  };

  const handleUpdateBot = (bot) => {
    const data = { bot, action: "update" };
    navigate("/details", { state: data });
  };

  const handleCreateBot = () => {
    const data = { action: "create" };
    navigate("/details", { state: data });
  };

  const handleDeleteBot = (botId) => {
    console.log(user.user._id, botId);
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/file/delete`, {
        data: { userId: user.user._id, botId },
      })
      .then(() => {
        setBotList((prevBotList) =>
          prevBotList.filter((bot) => bot._id !== botId)
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatDate = (isoString) => {
    if (!isoString) return;
    if (isoString === "-") return isoString;
    const date = new Date(isoString);

    const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
    const optionsDate = { month: "long", day: "numeric", year: "numeric" };

    const timeFormatter = new Intl.DateTimeFormat("en-US", optionsTime);
    const dateFormatter = new Intl.DateTimeFormat("en-US", optionsDate);

    const time = timeFormatter.format(date);
    const formattedDate = dateFormatter.format(date);

    return `${time}, ${formattedDate}`;
  };

  const getStatusClass = (bot) => {
    if (bot.last_run_status === "Ongoing" && bot.isActive) {
      return "green";
    } else if (bot.last_run_status === "Success" && bot.isActive) {
      return "yellow";
    } else if (bot.last_run_status === "Failure" || !bot.isActive) {
      return "red";
    } else {
      return "";
    }
  };

  const getStatusText = (bot) => {
    if (bot?.last_run_status === "Ongoing" && bot.isActive) {
      return "Ongoing";
    } else if (bot?.last_run_status === "Success" && bot.isActive) {
      return "Success";
    } else if (bot?.last_run_status === "Failure" || !bot.isActive) {
      return "Inactive";
    } else {
      return "";
    }
  };

  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":").map(Number);

    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    // Adding leading zero to minutes if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${period}`;
  }
  // console.log(botList[0].lastSearchRecords[0].toUpperCase());

  const location = useLocation();

  return (
    <div className="botListContainer">
      {loading ? (
        <div className="spinnerContainer">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              position: "fixed",
              // marginBottom: "20px",
              zIndex: 1200,
            }}
          >
            <button
              style={{ marginRight: 15 }}
              className="createBotButton"
              onClick={handleCreateBot}
            >
              <LuPlus /> Create Bot
            </button>
            {console.log(user.user)}
            {location.pathname === "/bot-details" &&
              (user.user.email === process.env.REACT_APP_ADMIN_EMAIL_FIRST ||
                user.user.email ===
                  process.env.REACT_APP_ADMIN_EMAIL_SECOND) && (
                <button
                  style={{ marginLeft: 15 }}
                  className="createBotButton"
                  onClick={(e) => handleAdminDashboard(e, user)}
                >
                  Admin Dashboard
                </button>
              )}
          </div>
          <div className="fullPageGrid">
            {botList.length > 0 ? (
              botList.map((bot, index) => (
                <div key={index} className="botItem">
                  <div className="botTitle">
                    <h3>{bot.name ? bot.name : "Bot"}</h3>
                    <div className="botItemActions">
                      <button onClick={() => handleDeleteBot(bot._id)}>
                        <RiDeleteBin6Line />
                      </button>
                    </div>
                    <div>
                      <span>{getStatusText(bot)}</span>
                      <div
                        className={`statusCircle ${getStatusClass(bot)}`}
                      ></div>
                    </div>
                  </div>
                  <div onClick={() => handleUpdateBot(bot)}>
                    <p className="botInfo">
                      <span>Agencies File Name</span>{" "}
                      {bot?.agenciesFileName?.slice(9, -5)}
                    </p>
                    <p className="botInfo">
                      <span>Keywords File Name</span>{" "}
                      {bot?.keywordsFileName?.slice(9, -5)}
                    </p>
                    <p className="botInfo">
                      <span>Output File Name</span>{" "}
                      {bot?.bot_type?.toLowerCase() === "awardees" &&
                      bot?.awardeeContractsFileName
                        ? bot.awardeeContractsFileName.slice(17, -5)
                        : bot.bot_type?.toLowerCase() === "contracts" &&
                          bot?.vehicleCollectionsFileName
                        ? bot.vehicleCollectionsFileName.slice(14, -5)
                        : bot?.captureFileName
                        ? bot.captureFileName.slice(8, -5)
                        : "No File Name Available"}
                    </p>

                    <p className="botInfo">
                      <span>Frequency</span> {bot?.botFrequency}
                    </p>
                    <p className="botInfo">
                      <span>Scheduled Time</span>{" "}
                      {convertTo12HourFormat(bot?.scheduledTime.split(" ")[0])}
                    </p>
                    <p className="botInfo">
                      <span>Max Results</span> {bot?.maxNumberOfResults}
                    </p>
                    <p className="botInfo">
                      {bot?.bot_type?.toLowerCase() === "contracts" ? (
                        <>
                          <span>Last Date To Order</span>{" "}
                          {bot?.lastDateToOrder
                            ? formatDate(bot?.last_run_timestamp)
                                ?.split(",")
                                .slice(1)
                            : "None"}
                        </>
                      ) : (
                        <>
                          <span>Within 12 Months</span>{" "}
                          {bot?.withinNextTwelveMonths ? "Yes" : "No"}
                        </>
                      )}
                    </p>
                    <p className="botInfo">
                      <span>Last Updated At</span> {formatDate(bot?.updatedAt)}
                    </p>
                    <p className="botInfo">
                      <span>Last Run At</span>{" "}
                      {formatDate(bot?.last_run_timestamp)}
                    </p>
                    <p className="botInfo">
                      <span>Last Run Status</span> {bot?.last_run_status}
                    </p>
                    <p className="botInfo">
                      <span>Last RunTime</span> {bot?.runtime}
                    </p>
                    <p className="botInfo">
                      <span>Bot Type</span>{" "}
                      {bot?.bot_type?.charAt(0).toUpperCase() +
                        bot?.bot_type?.slice(1)}
                    </p>
                    <p className="botInfo">
                      <span>Quick Search</span>{" "}
                      {bot?.quickSearch ? "Yes" : "No"}
                    </p>
                    <p className="botInfo">
                      <span>Last Search Records</span>{" "}
                      {bot?.lastSearchRecords
                        ? bot?.lastSearchRecords?.charAt(0).toUpperCase() +
                          bot?.lastSearchRecords?.slice(1)
                        : "None"}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="noBots">No bots available</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BotList;
